body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

@keyframes shake {
  0% {
    left: -5%;
  }
  100% {
    left: 0px;
  }
}

.shake {
  position: absolute;
  animation: shake .1s infinite;
  animation-timing-function: linear;
  animation-delay: 0s;
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(.9, .9); /* Safari */
  }
  50% {
    -webkit-transform: scale(1.2, 1.2); /* Safari */
  }
  100% {
    -webkit-transform: scale(.9, .9); /* Safari */
  }
}

.pulse {
  animation: pulse 5s infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
}

@keyframes walk {
  0% {
    transform: skew(0deg,3deg);
  }
  50% {
    transform: skew(0deg,-3deg);
  }
  100% {
    transform: skew(0deg,3deg);
  }
}

.walk {
  animation: walk 5s infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
}

.disallowed-for-crawlers {
  display:none;
}